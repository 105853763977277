<template lang="html">
  <div id="">
    <!-- wp:paragraph -->
<h1>Cookies &amp; Privacy Policy</h1>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<h2>Cookies Policy</h2>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<h3>About Cookies</h3>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>QUENCH uses cookies and similar technologies, such as tags and pixels, to personalise and improve your user experience and to provide you with relevant online advertising.&nbsp;</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>This section provides more information about Cookies, including how we use them and how you can exercise your rights about our use of Cookies.&nbsp;</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>This policy acts in conjunction with our <a href="https://quench.ie/privacy" target="_blank" rel="noreferrer noopener">Privacy Policy</a>, which describes our data handling practices and is available below.</p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>What is a Cookie?</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and the Service more useful to you.</p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>How we use Cookies</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>Cookies help us provide important features and functionality on our website. They are used to improve your user experience. Generally, our cookies perform up to four different functions:</p>
<!-- /wp:paragraph -->

<!-- wp:list -->
<ul><li><strong>Strictly Necessary cookies</strong> : they allow you to move around on our websites and to use its features.</li><li><strong>Performance cookies</strong>: they help us collect data on the performance of our website such as the number of visitors, their time spent on the website and error messages.</li><li><strong>Functionality cookies:</strong> they help us increase the usability of our website by remembering your preferences and choices (e.g. language, region, login, and so on).</li><li><strong>Session cookies: </strong>they temporarily keep track of your movement from page to page so you aren’t asked for the same information you’ve already given to the website. Unlike persistent or permanent cookies which stay on your device until you manually delete them or until your browser deletes them based on the duration period specified in the persistent cookie file, session cookies are erased when you close your browser.</li></ul>
<!-- /wp:list -->

<!-- wp:paragraph -->
<p><strong>Third Party Cookies</strong></p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>Third party companies like analytics companies and ad networks generally use cookies to collect user information on an anonymous basis. They may use that information to build a profile of your activities on QUENCH and other websites that you've visited.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>If you would like to disable third party cookies, you can do so by going to the relevant third party website and opting out there.</p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>What are your cookies options?</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>If you don't like the idea of cookies or certain types of cookies, you can <a href="javascript:window.Metomic.raise()">modify your preferences here</a> or change your browser's settings to delete cookies that have already been set and to not accept new cookies. To learn more about how to do this, visit the help pages of your browser.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>Please note, however, that if you delete cookies or do not accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.</p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>Where can I find more information about cookies?</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>You can learn more about cookies by visiting the following third party websites:</p>
<!-- /wp:paragraph -->

<!-- wp:list -->
<ul><li><a href="http://www.allaboutcookies.org/" target="_blank" rel="noreferrer noopener">All About Cookies</a>,&nbsp;</li><li><a href="http://www.youronlinechoices.com/" target="_blank" rel="noreferrer noopener">Your Online Choices</a>,</li><li><a href="http://www.aboutads.info/choices/#completed" target="_blank" rel="noreferrer noopener">About Ads</a> websites.</li></ul>
<!-- /wp:list -->

<!-- wp:heading {"level":3} -->
<h3><strong>Contact Us</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>If you have any questions about our use of cookies, please contact us as described in our <a href="https://quench.ie/privacy" target="_blank" rel="noreferrer noopener">Privacy Policy</a>.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p><strong>Privacy Policy</strong></p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p><strong>Introduction</strong></p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>Effective from 01/06/2019.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>QUENCH operates https://quench.ie. This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>Here you will find information about:</p>
<!-- /wp:paragraph -->

<!-- wp:list {"ordered":true} -->
<ol><li>The kind of personal information we collect from you</li><li>How your personal information is used</li><li>Legal bases for data processing</li><li>Who do we share your information with?&nbsp;</li><li>Collecting data on children&nbsp;</li><li>How long will we keep data?&nbsp;</li><li>Profiling&nbsp;</li><li>Links to other websites</li><li>Your rights</li></ol>
<!-- /wp:list -->

<!-- wp:paragraph -->
<p><strong>1. Information we collect from you</strong></p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p><strong>We collect information from you:</strong></p>
<!-- /wp:paragraph -->

<!-- wp:list -->
<ul><li>When you pay for any of our products or services</li><li>When you register to receive one of our newsletters</li><li>When you use our websites</li><li>If you participate in any survey we undertake</li><li>When you enter a competition</li><li>If you contact us with a query or a complaint</li></ul>
<!-- /wp:list -->

<!-- wp:paragraph -->
<p><strong>We collect the following types of information:</strong></p>
<!-- /wp:paragraph -->

<!-- wp:list -->
<ul><li><strong>Contact information</strong></li></ul>
<!-- /wp:list -->

<!-- wp:paragraph -->
<p>When you use or subscribe to one of our services, such as signing up to an email newsletter, entering a competition, or participating in our surveys, we will ask you to provide information such as your name, email, phone number and/or postal address so that we can provide you with the services requested.</p>
<!-- /wp:paragraph -->

<!-- wp:list -->
<ul><li><strong>General personal information</strong></li></ul>
<!-- /wp:list -->

<!-- wp:paragraph -->
<p>When conducting surveys or running competitions, in addition to contact information, we may collect other personal information such as your gender, date of birth, marital status, etc.</p>
<!-- /wp:paragraph -->

<!-- wp:list -->
<ul><li><strong>Payment details</strong></li></ul>
<!-- /wp:list -->

<!-- wp:paragraph -->
<p>When you purchase a product or service from us, such as subscribing to QUENCH or attending one of our events, we will also ask you for your payment details in addition to your contact information, in order to secure payment and to authorise access to our products and services.</p>
<!-- /wp:paragraph -->

<!-- wp:list -->
<ul><li><strong>Log Data</strong></li></ul>
<!-- /wp:list -->

<!-- wp:paragraph -->
<p>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data").</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>
<!-- /wp:paragraph -->

<!-- wp:list -->
<ul><li><strong>Behavioural data&nbsp;</strong></li></ul>
<!-- /wp:list -->

<!-- wp:paragraph -->
<p>When you visit our website, we collect data on your online behaviour (eg time spent on website, items clicked on, etc).</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>When buying or enjoying a product or service from us, such as a subscription or downloading digital copies of a product, we will collect information about what products and services you have bought/enjoyed from us.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>When you register for a newsletter or take part in a survey orf, we may also collect data about your preferences, tastes and interests.</p>
<!-- /wp:paragraph -->

<!-- wp:list -->
<ul><li><strong>“Special” categories of data</strong></li></ul>
<!-- /wp:list -->

<!-- wp:paragraph -->
<p>We may collect data in online surveys that qualify as “special category of data”. These could be data sets such as biometric information (including voice samples, facial recognition, etc), or political opinions, gender identification, religion, philosophical beliefs, health, sexual orientation, etc.&nbsp;</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p><strong>Answering to any such surveys will always be optional, and we will make sure we have your consent to process this data.</strong> An explanation as to why and how we are processing this kind of data will be provided. Additional safeguards will be in place for using, storing and ultimately either deleting or anonymising this data.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p><strong>2. How your personal information is used</strong></p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>At QUENCH we collect different types of information for the following reasons:</p>
<!-- /wp:paragraph -->

<!-- wp:list -->
<ul><li>To provide personalised services unique to our individual users,</li><li>To monitor and improve the services we offer,</li><li>To sell advertising space on our websites. This helps us offer you some free relevant content,</li><li>To market products and services which we think would be relevant to you.</li></ul>
<!-- /wp:list -->

<!-- wp:heading {"level":3} -->
<h3><strong>Newsletter</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>By signing up to our newsletter you are giving us consent to use the email address provided to send you relevant content and updates about QUENCH Magazine, competitions or partnerships we may run, or any special offers we may be able to offer you.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>You can withdraw this consent for any newsletter by unsubscribing at any time.</p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>Advertising</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>We advertise on our site and in our newsletter in order to be able to provide you with some relevant content for free.&nbsp;</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>You can opt-in or out of our use of cookies at any time. If you are interested and want to learn more about these, including how to opt out, please see our <a href="https://quench.ie/privacy" target="_blank" rel="noreferrer noopener">Cookie Policy</a> above.&nbsp;</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p><strong>Please note, opting out of cookies does not mean you will not see any advertisements.</strong></p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>Social Media Advertising</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>We work with social media networks such as Facebook (Facebook Pixel) to show you our marketing advertisers, sponsors and partners’ most relevant products and services based on your interests. Data collected by the Facebook Pixel may also be anonymised and used to help improve the quality and effectiveness of our websites and marketing efforts.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>To limit Facebook’s tracking and profiling effort, please refer directly to your Facebook account settings. You can also find out more about the pixels and cookies we use on QUENCH by reading our <a href="https://quench.ie/privacy" target="_blank" rel="noreferrer noopener">Cookies policy.</a></p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>We may also participate in Facebook's 'Custom Audience' service. This service enables QUENCH to display to you personalised advertisements when you visit Facebook’s social media platforms. It converts your email address to a unique number that Facebook uses to match to unique numbers that Facebook generates from the email addresses of its users.You only be targeted by our campaigns using Facebook Custom Audiences if you have previously consented to the use of your data information for advertising purposes or signed up to our newsletter.&nbsp;</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>Should you have given us your consent but have changed your mind and wish to unsubscribe, you can unsubscribe from our marketing mailing list at any moment (linked in any email we send you) and adjust your cookie setting preferences through our preference centre.</p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>Research &amp; Insight</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>We will run surveys and polls through social networks platforms, emails, or directly through QUENCH.ie. We may send out invitations to people who pay for our products or services and to people who have opted in to receive communications from us.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>&nbsp;If you are interested and want to learn more about these, including how to opt-out, please see our <a href="https://quench.ie/privacy" target="_blank" rel="noreferrer noopener">Cookie Policy</a>.&nbsp;</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p><strong>Please note, opting out of cookies does not mean you will not see any advertisements.</strong></p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>Delivery of goods and services / Customer account management</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>The information you have provided will be used for the provision of goods and/or services that you request. Subsequently we will communicate with you about these products and services.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>Please note that when you buy products / services or download content from us / our website - such as subscription,a pdf file, etc. - we may contact you about your registration details, to manage any account or subscription you have with us, or to provide you with customer service.&nbsp;</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p><strong>This will be the case even if you have opted-out of, or declined to consent to receiving promotional emails.</strong></p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>Competitions</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>When entering one of our online competitions, we will process your information for the purposes of selecting a winner. If you have opted-in to receiving information from us about our products and services (e.g. receiving subscriptions offers, signing up for editorial newsletters etc) then we will use the information you have provided to make sure we send out to you relevant products and services (based on where you live, your age, gender, tastes, preferences, etc).</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>If you have opted-in to receive news and offers from any competition sponsors, your information will be shared with them.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>Further details about this is outlined below in the “‘4. Who do we share your information with” with section.</p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>Partnerships programmes</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>We work with our partners from the world of services, fashion, retail and goods so that we can present you with some outstanding offers and opportunities by either email or post. You will only ever receive partner messages from us if you have consented to receive promotional offers from our partners. Your personal information will not be disclosed to our partners.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>You will not be directly emailed by them and you will be free to unsubscribe at any time from advertising and sponsored newsletters.</p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>Customer enquiries and complaints</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>Any information provided by you when dealing with any of your complaints or enquiries made by you, or legally on your behalf, will be processed.</p>
<!-- /wp:paragraph -->

<!-- wp:heading -->
<h2><strong>3. Legal bases for data processing</strong></h2>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>We may collect and process your personal data and it is one of our duties to make sure you understand which of these lawful bases we are using to process your data.</p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>Consent</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>To that effect, it is important that your consent may be given in order to collect and process your data:</p>
<!-- /wp:paragraph -->

<!-- wp:list -->
<ul><li>When you sign up to any editorial newsletter</li><li>When you tick a box to receive email from QUENCH about news and offers</li><li>When you tick a box to consent to receive emails from QUENCH on behalf of our partners</li><li>When you give us your details to enter a competition</li><li>When you participate in any survey or poll</li></ul>
<!-- /wp:list -->

<!-- wp:paragraph -->
<p><strong>Legitimate interest</strong></p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>In specific situations, we require your data to pursue our legitimate interests in a way which might reasonably be expected as part of running our business and which does not materially impact your rights, freedom or interests:</p>
<!-- /wp:paragraph -->

<!-- wp:list -->
<ul><li>When personalising the marketing content we provide you</li><li>When you buy a product or service from us, or download content from our page, we will also use your details to send you direct marketing information by email or post, telling you about products and services and content we think you might be interested in</li><li>When you buy a product or service from us, or download content from our page from our page, we will send you direct marketing on behalf of our partners</li></ul>
<!-- /wp:list -->

<!-- wp:paragraph -->
<p>Legitimate interest is only used after&nbsp; the data processing has been evaluated and a necessary balancing test has been conducted.&nbsp;</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>Anyl data subjects will be given the right to object to the processing of their information at any time.</p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>Contractual obligations</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>In certain circumstances, your personal data will be needed in order to comply with our contractual obligations.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>For example, when making a material purchase that needs to be physically sent out to you, we will collect and process personal data such as your name and address in order to fulfil your purchase.</p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>Legal compliance</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>If the law requires us to, we may need to collect and process your data.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>For example, we can pass on the details of people involved in fraud or other criminal activity affecting us to law enforcement.</p>
<!-- /wp:paragraph -->

<!-- wp:heading -->
<h2><strong>4. Who do we share your information with?</strong></h2>
<!-- /wp:heading -->

<!-- wp:heading {"level":3} -->
<h3><strong>Service providers</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>We work with service providers to perform some tasks on our behalf. This might include activities such as: analysis, payments, marketing, etc and including tagging and blending non-personal site user data to provide aggregate demographics on our audiences. We may share your personal data with these providers in order to perform the necessary tasks.&nbsp;</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>However, they are obliged not to disclose or use it for any other purposes. Any third-party processing of your data on our behalf will be subject to security and confidentiality obligations consistent with this privacy notice and applicable law.</p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>Customer research &amp; insight</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>We may disclose de-personalised data (such as aggregated statistics) about the audience of our products and services and/or survey participants to describe our sales, customers personas, traffic patterns and other information to prospective partners, advertisers, investors and other reputable third parties, and for other lawful purposes. These statistics will never include identifiable personal information.</p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>Law enforcement organisations</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>Under certain circumstances we may occasionally be required by law, court order or governmental authority to disclose certain types of personal information and we reserve ourselves the right to comply with any such legally binding request.</p>
<!-- /wp:paragraph -->

<!-- wp:heading {"level":3} -->
<h3><strong>Marketing partners</strong></h3>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>With your explicit permission, we may share your information with other companies we are working with, such as partners and sponsors. Before doing so, we will always ask for your permission, will name the company and will always be clear that your information will be shared with another organisation. You will have the right to decline giving your consent.</p>
<!-- /wp:paragraph -->

<!-- wp:heading -->
<h2><strong>5. Collecting data on children</strong></h2>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>We do not knowingly collect and process information from underaged people (anyone under the age of 18)<strong>.</strong> The products, services and content are generally explicit and aimed at people aged 18 and over.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>If you are under the age of 18, we ask that you do not give us any information about you or use our website.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>If you are a parent or guardian of a minor under 18 and if you think that your child has used our website, subscribed to a newsletter or provided their information to us without your consent, please contact us at <a href="mailto:quench.men@gmail.com" target="_blank" rel="noreferrer noopener">quench.men@gmail.com</a> and we will delete and/or stop processing your child’s personal information within a reasonable time.</p>
<!-- /wp:paragraph -->

<!-- wp:heading -->
<h2><strong>6. How long will we keep data?</strong></h2>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>We will retain your personal information for no longer than is necessary for the purposes for which it is being processed, while taking into account any legal requirements and tax and accounting rules.&nbsp;</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>There are some circumstances where personal data may be stored for longer periods (e.g. archiving purposes in the public interest, scientific or historical research purposes).</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>Where you sign up to receive email marketing from us we will retain your e-mail address after you ‘opt-out’ of receiving emails to ensure that you may still receive non marketing related emails..&nbsp;</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>
<!-- /wp:paragraph -->

<!-- wp:heading -->
<h2><strong>7. Profiling</strong></h2>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>We may sometimes use the data you share with us, and that we collect about you when browsing our websites and products, to personalise our services and to tailor marketing content. For example, we may use the information you have provided to send you information about content, products or services we think may be of interest to you.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>Should you wish you no longer wish to receive this type of material, you will be able to unsubscribe at any point.</p>
<!-- /wp:paragraph -->

<!-- wp:heading -->
<h2><strong>8. Links to other websites</strong></h2>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>Our website may contain links to other websites run by other organisations. This privacy policy applies only to QUENCH.ie‚ so we encourage you to read the privacy statements on any other websites you visit. <strong>We cannot be responsible for the privacy policies and practices of other sites even if you have accessed them using links from our websites.</strong></p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>We receive commission on some of the links to third party websites offering content, products and services that we feature on our websites.</p>
<!-- /wp:paragraph -->

<!-- wp:paragraph -->
<p>You can find out more in our <a href="https://quench.ie/privacy" target="_blank" rel="noreferrer noopener">Cookie Policy</a>.</p>
<!-- /wp:paragraph -->

<!-- wp:heading -->
<h2><strong>9. Your rights</strong></h2>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>As a consumer, you have rights when it comes to your data:</p>
<!-- /wp:paragraph -->

<!-- wp:list {"ordered":true} -->
<ol>
  <li><strong>The right to be informed.</strong> At every point where we are collecting your data, we will inform you of why it is being collected and how it is being processed.</li>
  <li><strong>The right of access.</strong> If you wish to see what information we hold on you, you may send us what is called a Subject Access request. We will require photocopies of two pieces of identification. We will respond within 30 days of receipt of your request. There is no cost to you in requesting to see your data, but please note that we reserve the right to charge you if you make subsequent requests. We will not pay for the posting of the documents we require in order to send you your data. You can use the contact details below for a Subject Access Request.&nbsp;</li>
  <li><strong>The right to rectification.</strong> We offer the opportunity to amend your personal information by contacting us using the details below.</li>
  <li><strong>The right to erasure.</strong> If you wish to cancel all communications with us, we can anonymise or de-identify your data. Please contact us using the details below. Please make sure you inform us of <strong>all </strong>email accounts, profiles, names etc that you may have used with us that you wish to erase.</li>
  <li><strong>The right to restrict processing.</strong> You can opt-out or restrict the processing of your data by:</li>
<!-- /wp:list -->

<!-- wp:list -->
<ul>
  <li>Amending your cookie settings in your browser</li><li>Unsubscribing from postal and email communications, using our preference center</li><li>Contacting us at quench.men@gmail.com</li></ul>
  </ol>
<!-- /wp:list -->

<!-- wp:paragraph -->
<p>You have the right to complain to a supervisory authority. In Ireland, this is the <a href="https://www.dataprotection.ie/" target="_blank" rel="noreferrer noopener">Data Protection Commissioner</a>.</p>
<!-- /wp:paragraph -->

<!-- wp:heading -->
<h2><strong>10. How to contact us</strong></h2>
<!-- /wp:heading -->

<!-- wp:paragraph -->
<p>Thank you for reading our Privacy Policy. If you would like to contact us to understand more about our privacy policy or wish to contact us concerning any matter relating to individual rights and your personal information, then please email or write to us by email at quench.men@gmail.com.</p>
<!-- /wp:paragraph -->
  </div>
</template>
<script>
export default {
  name: '',
  data: () => ({

  })
}
</script>
<style lang="scss" scoped>
h1, h2, h3, h4, p{
  margin-bottom: .5em;
  margin-top: 1em;
}
ul, ol {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
    ul {
       list-style-type: circle;
       list-style-position: inside;
       margin-left: 15px;
    }
    ol {
       list-style-type: lower-latin;
       list-style-position: inside;
       margin-left: 15px;
    }
}
ol {
   list-style-type: decimal;
}
a {
  color: #fff;
  transition: all ease 200ms;
  &:hover {
    color: rgba(#fff, 0.8);
  }
}
</style>
