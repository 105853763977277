<template>
  <div class="about">
    <div class="c-thank-you">
      <router-link :to="{ name: 'Home'}"><img alt="QUENCH logo" class="u-p c-logo" src="@/assets/logo.png"></router-link>
      <div class="u-text-left">
        <legal class="u-ph"/>
      </div>
    </div>
    <q-footer />

  </div>
</template>
<script>
import QFooter from '@/components/QFooter.vue'
import Legal from '@/components/Legal.vue'
export default {
  name: 'privacy',
  metaInfo: {
    metaTags: [
      {
        name: 'robots',
        content: 'noindex,nofollow'
      }
    ]
  },
  components: {
    QFooter,
    Legal
  }
}
</script>
